import React from "react";
import { Link } from "react-router-dom";
import * as images from "../../src/utilities/images";
import useMediaQuery from "@mui/material/useMediaQuery";

const Sidebar = () => {
  const pathName = window.location.pathname;
  const getScreenSize = useMediaQuery("(max-width:991px)");

  return (
    <div>
      <aside className="sidebarSil main-sidebar sideBar-bg sidebar-dark-primary main">
        <div className="cross-icon-logo">
          <Link to="/" className="brand-link">
            <img
              src={images.brandLogo}
              className="brand_logo"
              alt="Brand Logo"
              style={{ width: "100px" }}
            />
          </Link>
          <i
            data-widget={getScreenSize ? "pushmenu" : ""}
            className="fa fa-times cross-icon-small d-lg-none d-sm-block"
          ></i>
        </div>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              <li className="nav-item sideheading">
                <Link
                  data-widget={getScreenSize ? "pushmenu" : ""}
                  to="/"
                  className={
                    ["/"].includes(pathName) ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-th"></i>
                  <p>Dashboard</p>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  data-widget={getScreenSize ? "pushmenu" : ""}
                  to="/user-list"
                  className={
                    ["/user-list"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-users"></i>
                  <p>Users</p>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  data-widget={getScreenSize ? "pushmenu" : ""}
                  to="/info-pages"
                  className={
                    ["/info-pages"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-info"></i>
                  <p>Info Pages</p>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  data-widget={getScreenSize ? "pushmenu" : ""}
                  to="/contact"
                  className={
                    ["/contact"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className=" nav-icon fas fa-address-card"></i>
                  <p>Contact Details</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  data-widget={getScreenSize ? "pushmenu" : ""}
                  to="/chat-reports"
                  className={
                    ["/chat-reports"].includes(pathName) ||
                    pathName.includes("/chat")
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className=" nav-icon fas fa-comment-dots"></i>
                  <p>Chat Reports</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
