import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setAdminLogin,
  setAdminLogout,
  setForgotPassword,
  setEnterOtp,
  setrResendEnterOtp,
  setResetPassword,
} from "../../slices/auth";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* resetPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RESET_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setResetPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* resendEnterOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RSEND_ENTER_OTP),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setrResendEnterOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* enterOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ENTER_OTP),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setEnterOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* forgotPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGOT_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setForgotPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.data[0]);
  }
}

function* adminLogin(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ADMIN_LOGIN),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.setItem(
        "adminAuthToken",
        resp.data && resp.data.data.token ? resp.data.data.token : ""
      );
      yield put(setAdminLogin(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* adminLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_ADMIN),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.removeItem("adminAuthToken");
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
      yield put(setAdminLogout());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* authSaga() {
  yield all([takeLatest("auth/adminLogin", adminLogin)]);
  yield all([takeLatest("auth/adminLogout", adminLogout)]);
  yield all([takeLatest("auth/forgotPassword", forgotPassword)]);
  yield all([takeLatest("auth/enterOtp", enterOtp)]);
  yield all([takeLatest("auth/resendEnterOtp", resendEnterOtp)]);
  yield all([takeLatest("auth/resetPassword", resetPassword)]);
}

export default authSaga;
