import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteHelperPage, onErrorStopLoad } from "../../redux/slices/web";
import * as images from "../../utilities/images";

const DeleteContentModal = (props) => {
  const { close, showContent, getHelperPagesContent } = props;
  const dispatch = useDispatch();

  // delete helper page
  const handleDeleteHelperPage = () => {
    let params = {
      id: showContent.id,
    };
    dispatch(
      deleteHelperPage({
        ...params,
        cb(res) {
          if (res.status === 200) {
            close();
            getHelperPagesContent();
          }
        },
      })
    );
  };

  // stop loading
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="deleteAccount_">
      <div className="container-fluid">
        <div className="deleteModalImg">
          <img
            src={images.DeleteImg}
            alt="deleteimg"
            className="img-fluid .contactusImg "
          />
        </div>
        <h2 className="deleteheading">Delete Helper Page</h2>
        <p className="paraHeading">
          Are you sure, you want to delete this helper page
        </p>
        <div className="modalfooterbtn deleteBtnModal">
          <button onClick={close} className="cancelModalbtn">
            cancel
          </button>
          <button onClick={handleDeleteHelperPage} className="deleteBtn">
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteContentModal;
