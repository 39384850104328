import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteContactDetail, onErrorStopLoad } from "../../redux/slices/web";
import * as images from "../../utilities/images";

const DeleteModal = (props) => {
  const { messageData, close, getContactDetails } = props;
  const dispatch = useDispatch();

  // delete contact
  const deleteMessage = () => {
    let params = {
      id: messageData.id,
    };
    dispatch(
      deleteContactDetail({
        ...params,
        cb(res) {
          if (res.status === 200) {
            close();
            getContactDetails();
          }
        },
      })
    );
  };

  // stop loading on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="deleteAccount_ delete-contact-us">
        <div className="container-fluid">
        <div className="deleteModalImg">
          <div>
            <img
              src={images.DeleteImg}
              alt="deleteimg"
              className="img-fluid .contactusImg "
            />
          </div>
          <h2 className="deleteheading">Delete Contact</h2>
          <p className="paraHeading">
            Are you sure, you want to delete this Contact detail?
          </p>
          </div>
          <div className="modalfooterbtn deleteBtnModal">
            <button onClick={close} className="cancelModalbtn">
              cancel
            </button>
            <button onClick={deleteMessage} className="deleteBtn">
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
