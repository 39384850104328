import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector()
  const [formData, setFormData] = useState({
    email: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email) {
      toast.error("Please enter your email");
      return
    }
    let params = {
      email: formData.email.trim(),
    };
    dispatch(
      forgotPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/enter-otp");
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg adminpwd">
          <div className="card card-outline card-primary cardTopOutline cardBg">
            <div className="loginPage">
              <div className="card-header button-es text-center">
                <h3 className="mb-0 logintext">Forgot Password</h3>
              </div>
              <div className="card-body login-card-body mt-4">
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                 
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control form-input borderinput"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                     <label htmlFor="username" className="form-label d-block inputBox ">
                    Email{" "}
                  </label>
                  </div>

                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        className="loginBtnCommon btnYellow mt-5 mw-100  loginbtn"
                        type="submit"
                      >
                        {authData.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default ForgotPassword;
