import React from 'react'

const NotificationModal = () => {
    return (
        <>
            <div className='notificationSection'>
                <h6 className='modalclearAll text-end'>Clear All</h6>
                <div className='modalscroll'>
                    <div className='notificationModal reademessage'>
                        <p className='notificationText'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <h6 className='notificationTime'>02:21 pm</h6>
                    </div>
                    <div className='notificationModal reademessage'>
                        <p className='notificationText'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <h6 className='notificationTime'>02:21 pm</h6>
                    </div>
                    <div className='notificationModal unreadmessage'>
                        <p className='notificationText'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <h6 className='notificationTime'>02:21 pm</h6>
                    </div>
                    <div className='notificationModal unreadmessage'>
                        <p className='notificationText'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <h6 className='notificationTime'>02:21 pm</h6>
                    </div>
                    <div className='notificationModal unreadmessage'>
                        <p className='notificationText'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <h6 className='notificationTime'>02:21 pm</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationModal