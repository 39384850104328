import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../utilities/images";
import moment from "moment";

const ChatReportDetails = () => {
  document.title = "Chat Report Details";
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [reportMessage, setReportMessage] = useState(state?.data);

  // redirect to chat report page
  useEffect(() => {
    if (!reportMessage) {
      navigate("/chat-reports");
    }
  }, []);

  return (
    <>
      <div className="content-wrapper ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Chat Report Details</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content commonBox_  admindash">
          <div className="container-fluid chat-report-detail-outer">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="report-messgae-img">
                  <img
                    alt="report-messgae"
                    src={
                      reportMessage?.reportedUser?.userInfo?.profilePhoto
                        ? reportMessage?.reportedUser?.userInfo?.profilePhoto
                        : Images?.dummyProfile
                    }
                  />
                </div>
                <h1 className="report-by-name mt-2 text-center ">
                  {" "}
                  {reportMessage?.reportedUser?.userInfo?.firstName}{" "}
                  {reportMessage?.reportedUser?.userInfo?.lastName}
                </h1>
              </div>
              <p className="chat-report-date">
                {moment(reportMessage?.createdAt).format("DD-MM-YYYY")}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {moment(reportMessage?.createdAt).format("hh:mm A")}
              </p>
            </div>
            <div className="mt-2">
              <h1 className="chat-dest">Description</h1>
              <p className="chat-report-date">{reportMessage?.message}</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChatReportDetails;
