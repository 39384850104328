import React from "react";

const MessageModal = ({ messageData }) => {
  const { message } = messageData;
  return (
    <>
      <div className="message-outer">
        <p>{message}</p>
      </div>
    </>
  );
};

export default MessageModal;
