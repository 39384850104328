import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const [errorMessages, setErrorMessages] = useState({});
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // validation of input fields
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.password) {
      formIsValid = false;
      errors = { ...errors, password: "Please enter your password." };
    } else if (!formData.confirmpassword) {
      formIsValid = false;
      errors = {
        ...errors,
        confirmpassword: "Please enter your confirm password.",
      };
    }

    setErrorMessages(errors);
    return formIsValid;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = {
        new_password: formData.password,
        confirm_password: formData.confirmpassword,
        user_id: authData?.chefId?.user_id,
      };
      dispatch(
        resetPassword({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              navigate("/login");
            }
          },
        })
      );
    }
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg resetpwd">
        <div className="card card-outline card-primary cardTopOutline cardBg">
          <div className="loginPage">
            <div className="card-header text-center">
              <h3 className="mb-0 logintext">Reset Password</h3>
            </div>
            <div className="card-body login-card-body">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="input-container ">
                  <input
                    type="password"
                    className="form-control form-input borderinput"
                    name="password"
                    placeholder="enter your password"
                    value={formData.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label
                    htmlFor="username"
                    className="form-label d-block inputBox"
                  >
                    Password{" "}
                  </label>
                  <span className="error invalid-feedback">
                    {errorMessages.password}
                  </span>
                </div>

                <div className="input-container mt-4 mb-2">
                  <input
                    type="password"
                    className="form-control form-input borderinput"
                    name="confirmpassword"
                    placeholder="Password"
                    value={formData.confirmpassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label
                    htmlFor="username"
                    className="form-label d-block inputBox"
                  >
                    Confirm Password
                  </label>
                  <span className="error invalid-feedback">
                    {errorMessages.confirmpassword}
                  </span>
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      className="loginBtnCommon btnYellow mt-5 mw-100 loginbtn"
                      type="submit"
                    >
                      {authData.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Reset Password</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
