import React from "react";

const ViewContentModal = (props) => {
  const { showContent } = props;
  
  return (
    <>
      <div className="modalscroll info-page-content">
      <h2>{showContent.title}</h2>
      <p dangerouslySetInnerHTML={{ __html: showContent.content }}></p>
      </div>
    </>
  );
};

export default ViewContentModal;
