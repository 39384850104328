import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStats } from "../../redux/slices/web";
import { Link, useNavigate } from "react-router-dom";
import { useWebSelector } from "../../redux/selector/web";

const Dashboard = () => {
  document.title = "Home";
  console.log("Homeeee");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [dashboardData, setDashboardData] = useState([]);

  // get dashboard data
  useEffect(() => {
    dispatch(
      getStats({
        cb(res) {
          if (res.status === 200) {
            setDashboardData(res.data.data);
          }
        },
      })
    );
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      {loading ? (
        <div className="loaderOuter d-flex justify-content-center">
          <div className="spinner-grow " role="status"></div>
        </div>
      ) : (
        <section className="content commonBox_  admindash">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6">
                <Link to={"/user-list"} state={"user"}>
                  <div className="small-box dashboxcolor">
                    <div className="inner">
                      <h3>{dashboardData?.userCount}</h3>
                      <p>Users</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add" />
                    </div>
                    <i className="fas " />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-6">
                <Link to={"/user-list"} state={"chef"}>
                  <div className="small-box dashboxcolor_two">
                    <div className="inner">
                      <h3>{dashboardData?.chefCount}</h3>
                      <p>Chefs</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-bread-slice"></i>{" "}
                    </div>
                    <i className="fas " />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-6">
                <Link to={"/user-list"} state={"driver"}>
                  <div className="small-box dashboxcolor_threee">
                    <div className="inner">
                      <h3>{dashboardData?.driverCount}</h3>
                      <p>Drivers</p>
                    </div>
                    <div className="icon">
                      <i className="fa fa-truck"></i>
                    </div>
                    <i className="fas " />
                  </div>
                </Link>
              </div>

              <div className="col-lg-3 col-6">
                <div
                  onClick={() => navigate("/contact")}
                  className="small-box bg-warning dashthree"
                >
                  <div className="inner">
                    <h3>{dashboardData?.supportTickets}</h3>
                    <p>Support Tickets</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-sms" />
                  </div>
                  <i className="fas" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Dashboard;
