import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { adminLogin, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const [errorMessages, setErrorMessages] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // validation of input fields
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      errors = { ...errors, email: "Please enter your email." };
    } else if (typeof formData.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formData.email.trim())) {
        formIsValid = false;
        errors = { ...errors, email: "Please enter valid email." };
      }
    }

    if (!formData.password) {
      formIsValid = false;
      errors = { ...errors, password: "Please enter your password." };
    }
    setErrorMessages(errors);
    return formIsValid;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = {
        email: formData.email.trim(),
        password: formData.password,
        device_id: "2",
      };
      dispatch(
        adminLogin({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              navigate("/");
            }
          },
        })
      );
    }
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg adminlgn">
        <div className="card card-outline card-primary cardTopOutline cardBg">
          <div className="card-header text-center">
            <h3 className="mb-0 logintext">Admin Login</h3>
          </div>
          <div className="card-body login-card-body mt-4">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="input-container  ">
                <input
                  type="text"
                  className="form-control form-input borderinput"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label htmlFor="username" className="form-label d-block inputBox">
                  Email{" "}
                </label>
                <span className="error invalid-feedback">
                  {errorMessages.email}
                </span>
              </div>
              <div className="input-container mt-4 mb-2">
                <input
                  type="password"
                  className="form-control form-input borderinput"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label htmlFor="username" className="form-label d-block inputBox">
                  Password
                </label>
                <span className="error invalid-feedback">
                  {errorMessages.password}
                </span>
              </div>

              <div className="row">
                <div className="col-12 text-end">
                  <Link
                    to="/forgot-password"
                    className="
                         forgotheading"
                  >
                    Forgot password?
                  </Link>
                </div>

                <div className="col-12 text-center">
                  <button
                    disabled={authData.loading}
                    className="loginBtnCommon btnYellow mt-5 mw-100 loginbtn"
                    type="submit"
                  >
                    Sign In &nbsp;&nbsp;
                    {authData.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
