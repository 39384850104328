import React, { useEffect, useState } from "react";
import CustomModal from "../Modal/CustomModal";
import MessageModal from "../Modal/MessageModal";
import DeleteModal from "../Modal/DeleteModal";
import * as images from "../../utilities/images";
import { contactDetail, onErrorStopLoad } from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

const Contact = () => {
  document.title = "Contact";
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [contactData, setContactData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [messageData, setMessageData] = useState({
    message: "",
    id: "",
  });
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // close modal
  const handleOpenModal = (flag, message, id) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setMessageData({
      message: message,
      id: id,
    });
  };

  //get contact data
  useEffect(() => {
    getContactDetails();
  }, [search]);

  const getContactDetails = (page = currentPage) => {
    let params = {
      page: page,
      limit: 10,
      search: search,
    };
    dispatch(
      contactDetail({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setIsLoading(false);
            setContactData(res?.data?.data?.data);
            setPageCount(res?.data?.data?.total_pages);
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    getContactDetails(selected + 1);
  };

  return (
    <div className="content-wrapper contact-us-outer">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Contact</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}

      <section className="content commonBox_  admindash">
        <div className="container-fluid">
          <div className=" mt-4 userFilter">
            <div className="d-flex commonFilterHeader">
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="search"
                className="filterSearch"
                placeholder="search..."
              />
            </div>
          </div>
          <table className="usertable">
            <tr className="tableborder">
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email Address</th>
              <th>Message</th>
              <th>Action</th>
            </tr>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center ">
                      <div className="spinner-grow " role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {contactData && contactData.length > 0 ? (
                  <>
                    {contactData?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.firstName}</td>
                        <td>{item?.lastName}</td>
                        <td className="text-lowercase">{item?.email}</td>
                        <td>
                          <button
                            className="view-document-btn ms-0"
                            type="button"
                            onClick={() => {
                              handleOpenModal(
                                "contactMessage",
                                item?.message,
                                item?._id
                              );
                            }}
                          >
                            View Message
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              handleOpenModal(
                                "deleteModal",
                                item?.message,
                                item?._id
                              );
                            }}
                            className="view-document-btn ms-3"
                            type="button"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center ">
                        <p className="p-2">No data found</p>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </table>
          <div className="paginationMain_ mt-4">
            {contactData && contactData.length > 0 && (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "contactMessage"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "contactMessage"
            ? "messagemodal"
            : modalDetail.flag === "deleteModal"
            ? "messageDelete"
            : ""
        }
        child={
          modalDetail.flag === "contactMessage" ? (
            <MessageModal
              messageData={messageData}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteModal
              getContactDetails={getContactDetails}
              messageData={messageData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "contactMessage" ? (
            <>
              <h2 className="modal_Heading">Message</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : modalDetail.flag === "deleteModal" ? (
            <>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default Contact;
