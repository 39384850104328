import React, { useEffect, useState } from "react";
import {
  getHelperPages,
  onErrorStopLoad,
  updateHelperPageStatus,
} from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../redux/selector/web";
import moment from "moment";
import CustomModal from "../Modal/CustomModal";
import ViewContentModal from "../Modal/ViewContentModal";
import EditContentModal from "../Modal/EditContentModal";
import DeleteContentModal from "../Modal/DeleteContentModal";
import { Link } from "react-router-dom";
import * as images from "../../utilities/images";

const InfoPages = () => {
  document.title = "Info Page";
  const dispatch = useDispatch();
  const webData = useWebSelector();
  const [key, setKey] = useState(Math.random());
  const [helperPageData, setHelperPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showContent, setShowContent] = useState({
    title: "",
    content: "",
    id: "",
  });
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // close modal
  const handleOpenModal = (flag, title, content, id, slug) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setShowContent({
      title: title,
      content: content,
      id: id,
      slug: slug,
    });
  };
  // get content
  useEffect(() => {
    getHelperPagesContent();
  }, []);

  const getHelperPagesContent = () => {
    dispatch(
      getHelperPages({
        cb(res) {
          if (res.status === 200) {
            setIsLoading(false);
            setHelperPageData(res?.data?.data?.data);
          }
        },
      })
    );
  };

  // update helper pages status
  const updatePageStatus = (id, status) => {
    let params = {
      id: id,
      active: status,
    };
    dispatch(
      updateHelperPageStatus({
        ...params,
        cb(res) {
          if (res.status === 200) {
            getHelperPagesContent();
          }
        },
      })
    );
  };

  // stop loading on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Info Pages</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <section className="content commonBox_  userlistbox">
        <div className="container-fluid">
          <table className="usertable">
            <tr className="tableborder">
              <th>Id</th>
              <th>Title</th>
              <th>Create At</th>
              <th>Updated At</th>
              <th>Status</th>
              <th>Content</th>
              <th>Action</th>
            </tr>

            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center ">
                      <div className="spinner-grow " role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {helperPageData && helperPageData.length > 0 ? (
                  <>
                    {helperPageData?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.title}</td>
                        <td>
                          {moment(item.createdAt).format("DD-MM-YYYY hh:mm:ss")}
                        </td>
                        <td>
                          {moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss")}
                        </td>
                        {item?.active ? (
                          <td className="activeBtn">Active</td>
                        ) : (
                          <td className="inactivebtn">InActive</td>
                        )}

                        <td>
                          {" "}
                          <button
                            className="view-document-btn ms-3"
                            onClick={() =>
                              handleOpenModal(
                                "viewcontent",
                                item.title,
                                item.content,
                                item._id,
                                item.slug
                              )
                            }
                          >
                            View
                          </button>
                        </td>
                        <td>
                          {" "}
                          <div className="d-flex align-items-center">
                            <button
                              onClick={() =>
                                handleOpenModal(
                                  "editcontent",
                                  item.title,
                                  item.content,
                                  item._id,
                                  item.slug
                                )
                              }
                              className="view-document-btn ms-3"
                            >
                              Edit
                            </button>

                            <div className="dropdown filterDropdown">
                              <button
                                className="barbtn"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </button>

                              <ul className="dropdown-menu">
                                {!item?.active ? (
                                  <li>
                                    <Link
                                      className="dropdown-item font-14 userText"
                                      href="#"
                                      onClick={() => {
                                        updatePageStatus(item?._id, true);
                                      }}
                                    >
                                      Active
                                    </Link>
                                  </li>
                                ) : (
                                  <li>
                                    <Link
                                      className="dropdown-item font-14 userText"
                                      href="#"
                                      onClick={() => {
                                        updatePageStatus(item?._id, false);
                                      }}
                                    >
                                      InActive
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>{" "}
                          {/* <i
                            onClick={() =>
                              handleOpenModal(
                                "deletecontent",
                                item.title,
                                item.content,
                                item._id,
                                item.slug
                              )
                            }
                            title="delete content"
                            className="fa fa-trash"
                          ></i> */}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center">
                        <p className="p-2">No data found</p>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </table>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={true}
          mediumWidth={false}
          className={
            modalDetail.flag === "viewcontent"
              ? "commonWidth customContent"
              : modalDetail.flag === "editcontent"
              ? "commonWidth customContent"
              : modalDetail.flag === "deletecontent"
              ? "commonWidth customContent"
              : ""
          }
          ids={
            modalDetail.flag === "viewcontent"
              ? "viewModal"
              : modalDetail.flag === "deletecontent"
              ? "messagemodal"
              : modalDetail.flag === "editcontent"
              ? "messagemodal"
              : ""
          }
          size={
            modalDetail.flag === "editcontent"
              ? "xl"
              : "md"
              ? "viewModal"
                ? "xl"
                : "md"
              : ""
          }
          child={
            modalDetail.flag === "viewcontent" ? (
              <ViewContentModal
                showContent={showContent}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "editcontent" ? (
              <EditContentModal
                showContent={showContent}
                getHelperPagesContent={getHelperPagesContent}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "deletecontent" ? (
              <DeleteContentModal
                showContent={showContent}
                getHelperPagesContent={getHelperPagesContent}
                close={() => handleOnCloseModal()}
              />
            ) : (
              ""
            )
          }
          header={
            modalDetail.flag === "viewcontent" ? (
              <>
                <h2 className="modal_Heading">Content</h2>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img
                    src={images.modalCancel}
                    className="ModalCancel"
                    alt="modalcancel"
                  />
                </p>
              </>
            ) : modalDetail.flag === "editcontent" ? (
              <>
                <h2 className="modal_Heading">Edit Content</h2>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img
                    src={images.modalCancel}
                    className="ModalCancel"
                    alt="modalcancel"
                  />
                </p>
              </>
            ) : modalDetail.flag === "deletecontent" ? (
              <>
                <h2 className="modal_Heading">Delete Content</h2>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img
                    src={images.modalCancel}
                    className="ModalCancel"
                    alt="modalcancel"
                  />
                </p>
              </>
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </section>
    </div>
  );
};

export default InfoPages;
