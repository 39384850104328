import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PDF_WORKER_URL } from "../../config";
import { useWebSelector } from "../../redux/selector/web";
import { onErrorStopLoad, verifyDocument } from "../../redux/slices/web";

const DriverDocuments = ({ driverDocInfo, close, getAllAccountsDetails }) => {
  const [checkLoader, setCheckLoader] = useState("");
  const webData = useWebSelector();
  const dispatch = useDispatch();

  // verify document
  const handleVerifyDocument = (value) => {
    setCheckLoader(value);
    let params = {
      documentVerified: value,
      id: driverDocInfo._id,
    };
    dispatch(
      verifyDocument({
        ...params,
        cb(res) {
          if (res.status === 200) {
            close();
            getAllAccountsDetails();
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      {driverDocInfo?.driverInfo?.verificationDocument?.url ? (
        <div className="d-flex justify-content-between h-100 flex-column">
          <div className="chef-doc-main">
            <p className="doc-heading">Driver Document For Verification :-</p>
            {driverDocInfo?.driverInfo?.verificationDocument?.mimeType ===
            ("application/pdf" || "pdf") ? (
              <>
                <Worker workerUrl={PDF_WORKER_URL}>
                  <Viewer
                    fileUrl={
                      driverDocInfo?.driverInfo?.verificationDocument?.url
                    }
                  />
                </Worker>
              </>
            ) : (
              <img
                className="w-100 h-100 rounded"
                alt="chef-doc-img"
                src={driverDocInfo?.driverInfo?.verificationDocument?.url}
              />
            )}
          </div>
          <div className="mt-3">
            {driverDocInfo?.driverInfo?.verificationDocument?.url &&
              driverDocInfo?.driverInfo.documentVerified !== "verified" && (
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <button
                      disabled={webData?.loading}
                      onClick={() => handleVerifyDocument("rejected")}
                      className="foodmodalbtn"
                    >
                      Decline
                      {webData?.loading && checkLoader === "rejected" && (
                        <div
                          className="spinner-border loader ms-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <button
                      disabled={webData?.loading}
                      onClick={() => handleVerifyDocument("verified")}
                      className="foodmodalbtn d-flex align-items-center justify-content-center"
                    >
                      Approve
                      {webData?.loading && checkLoader === "verified" && (
                        <div
                          className="spinner-border loader ms-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <>
          {driverDocInfo?.driverInfo.documentVerified === "pending" ? (
            <div className="no-document-find">
              <p>No Document Found</p>
            </div>
          ) : (
            <div className="no-document-find">
              <p>No new documents have been uploaded yet.</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DriverDocuments;
