import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getSingleHelperPage,
  updateHelperPages,
  onErrorStopLoad,
} from "../../redux/slices/web";
import { CKEditor } from "ckeditor4-react";

const EditContentModal = ({ showContent, close, getHelperPagesContent }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [updateTitle, setUpdateTitle] = useState({
    id: "",
    title: "",
  });
  const [updateContent, setUpdatedContent] = useState(showContent.content);

  // getting the content
  useEffect(() => {
    let params = {
      id: showContent.id,
    };
    dispatch(
      getSingleHelperPage({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setUpdateTitle({
              title: res?.data?.data?.title,
              id: res?.data?.data?._id,
            });
          }
        },
      })
    );
  }, []);

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // update content
  const handleUpdateContent = () => {
    let params = {
      id: showContent.id,
      content: updateContent,
      annotations: [],
      slug: showContent.slug,
    };
    dispatch(
      updateHelperPages({
        ...params,
        cb(res) {
          if (res.status === 200) {
            close();
            getHelperPagesContent();
          }
        },
      })
    );
  };

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <>
      <div className="admintermBanner_" id="Terms">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <h3 className="medText">{updateTitle.title}</h3>
            <div className="contentOuter">
              {updateContent && (
                <div>
                  <CKEditor
                    key={updateTitle?.id}
                    initData={updateContent}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Table",
                        "TableToolbar",
                        "image",
                      ],
                      toolbarLocation: ["top"],
                      removeButtons:
                        "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                      versionCheck: false,
                    }}
                    name="editor"
                    readOnly={false}
                    onChange={(event, editor) => {
                      const data = event.editor.getData();
                      setUpdatedContent(data);
                    }}
                    type="classic"
                  />
                </div>
              )}
            </div>
          </>
        )}
        <div className="editfooterBtn mt-3">
          <button className="editCancelBtn" onClick={close}>
            Cancel
          </button>
          <button className="editcontentBtn" onClick={handleUpdateContent}>
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default EditContentModal;
