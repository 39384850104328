import React, { useEffect, useState } from "react";
import { getChatReports } from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { useWebSelector } from "../../redux/selector/web";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import * as images from "../../utilities/images";

const ChatReport = () => {
  document.title = "Chat Report";
  const navigate = useNavigate();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [reportMessage, setReportMessgae] = useState("");
  const [chatReport, setChatReport] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // chat report
  useEffect(() => {
    let params = {
      page: currentPage,
      limit: 10,
    };

    dispatch(
      getChatReports({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setChatReport(res?.data?.data?.data);
            setPageCount(res?.data?.data?.total_pages);
          }
        },
      })
    );
  }, [currentPage]);

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // handle open modal
  const handleOpneModal = (flag, message) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setReportMessgae(message);
  };

  // open detail page
  const handleOpenDetailPage = (data) => {
    navigate("/chat-report-details", {
      state: {
        data: data,
      },
    });
  };

  return (
    <>
      <div className="content-wrapper adminUsers">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Chat Report</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content commonBox_  userlistbox">
          <div className="container-fluid">
            <table className="usertable">
              <tr className="tableborder">
                <th>S.No</th>
                <th>CreatedAt</th>
                <th>Reported By</th>
                <th>Reported User</th>
                <th>Action</th>
              </tr>

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center ">
                        <div className="spinner-grow " role="status"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  {chatReport && chatReport.length > 0 ? (
                    <>
                      {chatReport?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {moment(item?.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {item?.reportedBy?.userInfo?.firstName}{" "}
                            {item?.reportedBy?.userInfo?.lastName}
                          </td>
                          <td>
                            {item?.reportedUser?.userInfo?.firstName}{" "}
                            {item?.reportedUser?.userInfo?.lastName}
                          </td>

                          <td>
                            <div className="dropdown filterDropdown">
                              <button
                                onClick={() => handleOpenDetailPage(item)}
                                className="view-document-btn ms-0"
                                type="button"
                          
                              >
                                View
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <div className="loaderOuter d-flex justify-content-center ">
                          <p className="p-2">No data found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
            <div className="paginationMain_ mt-4">
              {chatReport && chatReport.length > 0 && (
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  pageCount={pageCount}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={3}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "message" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "message" ? "tablemodalbtn" : ""}
        child={modalDetail.flag === "message" ? <></> : ""}
        header={
          modalDetail.flag === "message" ? (
            <>
              <h2 className="modal_Heading">Report Message</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ChatReport;
